import {React, useEffect} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {connect} from 'react-redux';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppRouter from './AppRouter';
import Auth from './components/Auth';
import history from './utils/history';
import { IntercomProvider } from 'react-use-intercom';

const INTERCOM_APP_ID = 'v6687evi'

const App = ({authToken}) => {
    const loggedIn = authToken;

    useEffect(async () => {
        const script = document.createElement('script');

        script.innerText = `window.STONLY_WID = "9c1ec2b8-ab1b-11ec-9fb8-0ae9fa2a18a2";`
        script.async = false;
        const script2 = document.createElement('script');
        script2.innerHTML = `!function(s,t,o,n,l,y,w,g){s.StonlyWidget||((w=s.StonlyWidget=function(){
		w._api?w._api.apply(w,arguments):w.queue.push(arguments)}).scriptPath=n,w.queue=[],(y=t.createElement(o)).async=!0,
		(g=new XMLHttpRequest).open("GET",n+"version?v="+Date.now(),!0),g.onreadystatechange=function(){
		4===g.readyState&&(y.src=n+"stonly-widget.js?v="+(200===g.status?g.responseText:Date.now()),
		(l=t.getElementsByTagName(o)[0]).parentNode.insertBefore(y,l))},g.send())
		}(window,document,"script","https://stonly.com/js/widget/v2/");`
        script2.async = false;

        document.head.appendChild(script);
        document.head.appendChild(script2);
    }, []);

    return (
        <IntercomProvider appId={INTERCOM_APP_ID} autoBoot={true}>
            <div className={loggedIn ? 'flex h-screen bg-gray-50' : 'bg-gray-50'}>
                <ToastContainer autoClose={2500} position="top-right" closeButton={false}/>
                <Router history={history}>{loggedIn ? <AppRouter/> : <Auth/>}</Router>
            </div>
        </IntercomProvider>
    );
};

const mapStateToProps = ({auth}) => ({authToken: auth.token});

export default connect(mapStateToProps)(App);
