import React from 'react';

function AppCard({ title, value }) {
	return (
		<div className="flex items-center p-4 bg-white rounded-lg shadow-xs border-2 cursor-pointer">
			<div className="mx-auto text-center">
				<p className="mb-2 pt-2 text-sm font-medium text-gray-600">{title}</p>
				<p className="mb-2 text-4xl font-semibold text-gray-700">{value}</p>
			</div>
		</div>
	);
}

export default AppCard;
