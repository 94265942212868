// Move to root after updating @friggframework/core to read

module.exports = {
	appDisplayName: 'Big SaaS',
	appIcon: {
		active: true,
		type: 'cloud-solid',
	},
	theme: 'default-purple',
	componentStyle: 'default-horizontal',
	componentFilter: {
		active: true,
		allTab: false,
		recentlyAddedTab: true,
		installedTab: true,
		categories: ['Marketing', 'Sales & CRM', 'Commerce', 'Social', 'Productivity', 'Finance'],
	},
	integrations: [
		'activecampaign',
		'hubspot',
		'mondaycom',
		'revio',
		'connectwise',
		'rollworks',
		'salesloft',
		'salesforce',
		'fastspring',
		'stack',
		'crossbeam',
		'marketo',
		'outreach',
	],
};
