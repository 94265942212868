import React, {useEffect} from 'react';
import {useIntercom} from "react-use-intercom";

function SettingsPage() {
    useEffect(async () => {
        const {update} = useIntercom()
        await update();
    }, []);
	return (
		<main className="h-full pb-16 overflow-y-auto">
			<div className="container px-6 mx-auto grid">
				<h2 className="my-6 text-2xl font-semibold text-gray-700">Settings</h2>
			</div>
		</main>
	);
}

export default SettingsPage;
